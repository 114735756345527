<template>
  <RecordViewWrapper>
    <sdPageHeader :title="pageData.title">
      <template #subTitle>
        <sdButton
          class="btn-add_new"
          size="default"
          type="primary"
          v-if="checkPermission('create laporan manajemen ' + pageData.title.toLowerCase())"
        >
          <router-link :to="'/laporan-manajemen/laporan-manajemen-add/' + $route.params.id">
            <sdFeatherIcons type="plus" size="14" /> Tambah</router-link
          >
        </sdButton>
      </template>
      <template #buttons>
        <div class="search-box">
          <span class="search-icon">
            <sdFeatherIcons type="search" size="14" />
          </span>
          <a-input
            @change="handleSearch"
            v-model:value.trim="formState.searchItem"
            type="text"
            name="recored-search"
            placeholder="Search Here"
          /></div
      ></template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  :pagination="{ pageSize: 10, showSizeChanger: true }"
                  :dataSource="dataSource"
                  :columns="columns.data"
                />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>

      <a-modal v-model:visible="modalState.visible" title="Konfirmasi">
        <a-form
          layout="horizontal"
          :model="formModalState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="modalRules"
          id="unapproveForm"
          @finish="doUnapprove"
        >
          Apakah Anda yakin ingin melakukan unapprove {{ pageData.title }} {{ modalState.month }} {{ modalState.year }}?
          <a-form-item name="alasan" label="Alasan">
            <a-input v-model:value="formModalState.alasan" placeholder="Masukkan Alasan" />
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" @click="handleCancel">Batal</a-button>
          <a-button class="sDash_form-action__btn" type="primary" size="large" htmlType="submit" form="unapproveForm">
            {{ modalState.isLoading ? 'Harap Menunggu...' : 'Unapprove' }}
          </a-button>
        </template>
      </a-modal>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { DataService } from '@/config/dataService/dataService';
import { notification } from 'ant-design-vue';
import { getItem } from '@/utility/localStorageControl';

const columns = reactive({
  data: [],
  isTotalNeeded: false,
});

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const { params } = useRoute();
    const profile = getItem('profile_plnbb');
    const permissions = getItem('permissions');

    const pageData = reactive({
      title: ' ',
    });

    onMounted(() => {
      getData();
    });

    const getData = () => {
      dispatch('axiosCrudGetData', 'laporan-manajemen/' + parseInt(params.id)).then(() => {
        pageData.title = crud.value.data.name;

        dispatch('axiosCrudGetData', 'laporan-laporan-manajemen/' + parseInt(params.id)).then(() => {
          columns.data = [
            {
              title: 'Bulan',
              dataIndex: 'month',
              key: 'month',
            },
            {
              title: 'Tahun',
              dataIndex: 'year',
              key: 'year',
            },
          ];

          crud.value.variables.forEach(data => {
            if (data.type == 'Nominal') {
              formState.isTotalNeeded = true;
            }

            columns.data.push({
              title: data.name,
              dataIndex: data.name,
              key: data.name,
              align: data.type == 'Nominal' ? 'right' : 'left',
            });
          });

          columns.data.push({
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
          });

          columns.data.push({
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            width: '90px',
          });
        });
      });
    };

    const handleDelete = (row_id, month, year) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `apakah anda yakin ingin menghapus Laporan Manajemen ${months[month]} ${year}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id: row_id + '?month=' + month + '&year=' + year,
            url: 'laporan-laporan-manajemen',
            getData: () => {
              dispatch('axiosCrudGetData', 'laporan-laporan-manajemen/' + parseInt(params.id));
            },
          });
        },
      });
    };

    const modalState = reactive({
      isLoading: false,
      visible: false,
      laporan: '',
      month: '',
      year: null,
      type: '',
    });

    const formModalState = reactive({
      id: null,
      alasan: '',
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const formState = reactive({
      searchItem: '',
    });

    const dataSource = computed(() => {
      if (crud.value && crud.value.data && crud.value.variables) {
        let rowTotal = {
          key: null,
          month: null,
          year: 'TOTAL',
        };

        let listItems = crud.value.data.map((data, key) => {
          const { id, row_id, month, year, nilais, status } = data;

          let color;

          switch (status) {
            case 'CRTD':
              color = 'blue';
              break;
            case 'UPDT':
              color = 'orange';
              break;
            case 'REL':
              color = 'green';
              break;
            case 'UNREL':
              color = 'volcano';
              break;
            case 'DEL':
              color = 'red';
              break;
          }

          let update = checkPermission('update laporan manajemen ' + pageData.title.toLowerCase()) ? (
            <router-link class="edit" to={`/laporan-manajemen/laporan-manajemen-edit/${id}/${row_id}/${month}/${year}`}>
              <sdFeatherIcons type="edit" size={14} title="Ubah" />
            </router-link>
          ) : null;

          let approve = checkPermission('approve laporan manajemen ' + pageData.title.toLowerCase()) ? (
            <a class="edit" onClick={() => handleApprove(data.row_id, month, year)}>
              <sdFeatherIcons type="check-square" size={14} title="Approve" />
            </a>
          ) : null;

          let del = checkPermission('delete laporan manajemen ' + pageData.title.toLowerCase()) ? (
            <router-link class="delete" onClick={() => handleDelete(data.row_id, month, year)} to="#">
              <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
            </router-link>
          ) : null;

          let unapprove = checkPermission('approve laporan manajemen ' + pageData.title.toLowerCase()) ? (
            <a class="edit" onClick={() => handleUnapprove(data.row_id, month, year)}>
              <sdFeatherIcons type="rotate-ccw" size={14} title="Unapprove" />
            </a>
          ) : null;

          let sources = {
            key: key + 1,
            month: months[month],
            year,
            status: status ? <a-tag color={color}>{status}</a-tag> : '',
            action: (
              <div class="table-actions">
                {status != 'REL' ? (
                  <>
                    {update}
                    {approve}
                    {del}
                  </>
                ) : (
                  { unapprove }
                )}
              </div>
            ),
          };

          crud.value.variables.forEach((item, key) => {
            let variable = item.name;
            sources[variable] = nilais[key][variable];
            if (formState.isTotalNeeded)
              if (item.type == 'Nominal') {
                if (rowTotal[variable]) {
                  rowTotal[variable] += parseFloat(nilais[key][variable]) || 0;
                } else {
                  rowTotal[variable] = parseFloat(nilais[key][variable]) || 0;
                }
              }
          });
          return sources;
        });

        if (formState.isTotalNeeded) {
          rowTotal.key = listItems.length + 1;
          listItems.push(rowTotal);
        }

        return listItems;
      } else {
        return [];
      }
    });

    const handleSearch = () => {
      dispatch('axiosDataSearch', {
        url: 'laporan-laporan-manajemen/' + parseInt(params.id),
        filter: formState.searchItem,
      });
    };

    const handleApprove = (id, month, year) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin melakukan approve ${pageData.title} ${months[month]} ${year}?`,
        okText: 'Approve',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataApprove', {
            id,
            url: 'approve-laporan-laporan-manajemen',
            getData: () => {
              getData();
            },
          });
        },
      });
    };

    const handleUnapprove = (id, month, year) => {
      formModalState.id = id;
      modalState.month = months[month];
      modalState.year = year;
      modalState.visible = true;
    };

    const handleCancel = () => {
      modalState.visible = false;
    };

    const doUnapprove = () => {
      modalState.isLoading = true;

      DataService.post('unapprove-laporan-laporan-manajemen/' + formModalState.id, {
        alasan: formModalState.alasan,
      }).then(response => {
        modalState.visible = false;
        modalState.isLoading = false;
        formModalState.id = null;
        formModalState.alasan = '';
        notification.success({
          message: response.data.message,
        });

        getData();
      });
    };

    const modalRules = {
      alasan: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const checkPermission = permission => {
      if (profile.roles[0].name == 'Super Admin') {
        return true;
      }

      return permissions.includes(permission);
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleSearch,
      pageData,
      labelCol,
      wrapperCol,
      handleApprove,
      handleUnapprove,
      handleCancel,
      doUnapprove,
      modalState,
      formModalState,
      modalRules,
      checkPermission,
    };
  },
};

export default ViewPage;
</script>
